<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <h5>Запросы на выпуск ЦФА</h5>
      <hr />
      <DataTable
        :value="issuers"
        dataKey="id"
        v-model:issuers="issuers"
        responsiveLayout="scroll"
        :sortable="true"
        paginator
        lazy
        :rows="rows"
        :totalRecords="totalItems"
        @page="setPage"
      >
        <Column
          style="width: 20%; word-break: break-all"
          header="Актив"
          field="tokenNameEng"
        />
        <Column
          style="width: 10%; word-break: break-all"
          header="Абр"
          field="tokenMnemonicEng"
        />
        <Column
          style="width: 40%; word-break: break-all"
          header="Кол"
          field="tokenCount"
        />
        <Column header="Статус" style="width: 15%; word-break: break-all">
          <template #body="slot">
            {{ slot.data.confirmedAt == null ? 'Ожидание' : 'Исполнен' }}
          </template>
        </Column>
        <Column
          style="width: 10%; word-break: break-all"
          header="Черновик"
          field="isDraft"
        />
        <Column
          class="bg-white border-none flex flex-nowrap justify-content-start"
        >
          <template #body="slot">
            <div data-help="Утвердить">
              <Button
                icon="pi pi-check"
                class="p-button-rounded p-button-success m-1"
                @click="showBlockChainDialog(slot.data)"
              />
            </div>
            <div data-help="Черновик">
              <Button
                icon="pi pi-pencil"
                class="send p-button-rounded p-button-warning m-1"
                @click="editDraft(slot.data)"
              />
            </div>
            <div data-help="Удалить">
              <Button
                icon="pi pi-trash"
                class="sale p-button-rounded p-button-danger m-1"
                @click="showRemoveDialog(slot.data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    v-model:visible="blockchainDialog"
    header="Утвердить запрос"
    class="p-fluid"
  >
    <label for="blockchainList">Выберите необходимый блокчейн</label>
    <Dropdown
      id="blockchainList"
      v-model="selectedBlockchain"
      :options="blockchainList"
      option-label="name"
    />
    <br />
    <label for="decimals">Количество знаков после запятой</label>
    <InputText
      id="decimals"
      v-model="decimals"
      placeholder="Знаков после запятой"
      type="number"
    />
    <br /><br />
    <Button
      :disabled="!selectedBlockchain"
      label="Ок"
      class="p-button-primary"
      @click="confirm"
    />
  </Dialog>
  <Dialog
    v-model:visible="removeDialog"
    header="Удалить Актив?"
    class="p-fluid"
  >
    <p>{{ selectedItem.tokenName }}</p>
    <br />
    <div class="bg-white border-none flex flex-nowrap justify-content-between">
      <div>
        <Button
          label="Нет"
          class="p-button-warning"
          @click=";(removeDialog = false), (selectedItem = {})"
        />
      </div>
      <div>
        <Button label="Да" class="p-button-danger" @click="removeItem" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import moment from 'moment/moment'

export default {
  data() {
    return {
      issuers: [],
      blockchainDialog: false,
      removeDialog: false,
      selectedItem: {},
      blockchainList: [],
      selectedBlockchain: null,
      selectedOrderId: 0,
      decimals: null,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },

  async mounted() {
    this.getRequestToIssues()
    this.getBlockChainList()
  },

  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getRequestToIssues()
    },
    async getRequestToIssues() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
        isCompleted: false
      }
      try {
        const resp = await this.axios.get('api/request_to_issues', { headers, params })
        this.issuers = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Request not created',
          group: 'info',
          life: 3000,
        })
      }
    },
    async confirm() {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const currentDate = moment()
      const params = {
        isDraft: false,
        confirmedAt: currentDate,
        blockChain: `/api/block_chains/${this.selectedBlockchain.id}`,
        decimals: Number(this.decimals),
      }
      try {
        await this.axios.put(
          `api/request_to_issues/${this.selectedOrderId}`,
          params,
          headers
        )
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Request success',
          group: 'info',
          life: 3000,
        })
        for (let i = 0; i < this.issuers.length; i++) {
          if (this.issuers[i].id === this.selectedOrderId) {
            this.issuers.splice(i, 1)
            break
          }
        }
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Confirm request error',
          group: 'info',
          life: 3000,
        })
      }
    },
    async getBlockChainList() {
      try {
        const resp = await this.axios.get('api/block_chains')
        this.blockchainList = resp.data
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Blockchain request error',
          group: 'info',
          life: 3000,
        })
      }
    },
    showBlockChainDialog(value) {
      this.selectedOrderId = value.id
      this.decimals = value.decimals
      this.blockchainDialog = true
    },
    showRemoveDialog(value) {
      this.selectedItem = value
      this.removeDialog = true
    },
    async editDraft(value) {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/merge-patch+json',
      }
      const params = {
        isDraft: !value.isDraft,
      }
      try {
        await this.axios.patch(`api/request_to_issues/${value.id}`, params, {
          headers,
        })
        this.issuers.find((item) => {
          if (item.id == value.id) {
            item.isDraft = !value.isDraft
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async removeItem() {
      this.removeDialog = false
      try {
        await this.axios.delete(`api/request_to_issues/${this.selectedItem.id}`)
        this.issuers = this.issuers.filter(
          (item) => item.id !== this.selectedItem.id
        )
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: -5px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>
